<template>
	<div>
		<CRow>
			<div class="col-md-12">
				<CTabs style="background-color: #ffffff">
					<CTab title="Order Items" active>
						<CRow>
							<CCol md="12" class="m-2">
								<table class="table table-sm table-hover">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Kode</th>
											<th scope="col">Product</th>
											<th scope="col">Harga Beli<br />Satuan</th>
											<th scope="col">Harga Jual<br />Satuan</th>
											<th scope="col">Diskon<br />Satuan</th>
											<th scope="col">Jumlah</th>
											<th scope="col">Total</th>
										</tr>
									</thead>
									<tbody>
										<tr :key="i" v-for="(p, i) in rowData.orderDetails">
											<td>{{ i + 1 }}</td>
											<td>{{ p.productItem.kode }}</td>
											<td
												v-html="
													p.productItem.product.nama +
													'<br>' +
													size[p.productItem.size].nama
												"
											></td>
											<td class="text-right">
												{{ p.hargaBeliSatuan.toLocaleString() }}
											</td>
											<td class="text-right">
												{{ p.hargaSatuan.toLocaleString() }}
											</td>
											<td class="text-right">
												{{ p.diskonSatuan.toLocaleString() }}
											</td>
											<td class="text-center">{{ p.jumlah }}</td>
											<td class="text-right">
												{{ p.totalBayar.toLocaleString() }}
											</td>
										</tr>
									</tbody>
								</table>
							</CCol>
						</CRow>
					</CTab>
				</CTabs>
			</div>
		</CRow>
	</div>
</template>

<script>
import { size } from "@/plugins/utils";
export default {
	data() {
		return {
			size,
		};
	},
	props: {
		rowData: {
			type: Object,
			required: true,
		},
		rowIndex: {
			type: Number,
		},
	},
};
</script>
