<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader> Order List </CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="3">
								<CInput
									label=""
									placeholder="Enter Filter"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="3">
								<div role="group" class="form-group">
									<select
										v-model="moreParams.status"
										id="asal"
										class="form-control"
									>
										<option :value="null">status all</option>
										<option
											:key="a"
											v-for="a in status"
											:data-key="a"
											:value="a"
										>
											{{ a }}
										</option>
									</select>
								</div>
							</CCol>
							<CCol md="2">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top: 0px"
										size="sm"
									>
										<CIcon name="cil-reload" />
									</CButton>
									<CButton
										@click="add"
										id="btn"
										color="success"
										size="sm"
										style="margin-top: 0px; margin-left: 5px"
									>
										<CIcon name="cil-plus" />
									</CButton>
								</div>
							</CCol>
							<CCol md="3">
								<div role="group" class="form-group">
									<select @change="setStatus" id="asal" class="form-control">
										<option :value="0">Set Status TIDAK</option>
										<option :value="1">Set Status YA</option>
									</select>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height: 900px">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								:detail-row-component="detailRow"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
							>
								<template slot="slot-actions" slot-scope="props">
									<CButton
										@click="showDetail(props.rowData.id)"
										color="secondary"
										size="sm"
										style="margin-left: 0px"
										class="py-0"
									>
										<CIcon name="cil-chevron-double-down" />
									</CButton>
								</template>
								<template slot="slot-tujuan" slot-scope="props">
									{{ props.rowData.tujuanNama }}<br />
									{{ props.rowData.tujuanHp }}<br />
									{{ props.rowData.tujuanAlamat }}
								</template>
								<template slot="slot-kirim" slot-scope="props">
									{{ props.rowData.asal.toUpperCase() }}<br />
									{{ props.rowData.kurier.toUpperCase() }}<br />
									<b>{{ props.rowData.resi.toUpperCase() }}</b>
								</template>
								<template slot="slot-kode" slot-scope="props">
									{{ props.rowData.kode.toUpperCase() }}<br />
									<CBadge
										:color="colorBadge(props.rowData.status)"
										class="mfs-auto"
										style="cursor: pointer"
										>{{ props.rowData.status.toUpperCase() }}</CBadge
									>
								</template>
								<template slot="slot-user" slot-scope="props">
									Insert : {{ props.rowData.insertNama }}<br />
									Packer :
									<CBadge
										v-if="!props.rowData.packerNama"
										color="danger"
										class="mfs-auto"
										style="cursor: pointer"
										>MENUNGGU</CBadge
									>
									{{ props.rowData.packerNama ? props.rowData.packerNama : "" }}
								</template>
								<template slot="slot-harga" slot-scope="props">
									Harga : {{ props.rowData.harga.toLocaleString() }}<br />
									Diskon : {{ props.rowData.diskon.toLocaleString() }}<br />
									Total : {{ props.rowData.hargaDiskon.toLocaleString() }}
								</template>
								<template slot="slot-setstatus" slot-scope="props">
									<CBadge
										:color="colorBadge(props.rowData.status)"
										class="mfs-auto"
										style="cursor: pointer"
										>{{ props.rowData.status.toUpperCase() }}</CBadge
									><br />
									<div class="col-sm-12 form-inline pl-0">
										<div
											v-for="s in status"
											:key="s"
											role="group"
											class="custom-control custom-control-inline custom-radio"
										>
											<input
												:id="props.rowData.id + s"
												type="radio"
												:name="'radio' + props.rowData.id"
												class="custom-control-input"
												:value="s"
												style="cursor: pointer"
												:checked="s === props.rowData.status"
												@change="
													changeStat(
														props.rowData.id,
														s,
														props.rowData.catatanStatus,
														true
													)
												"
											/>
											<label
												:for="props.rowData.id + s"
												style="cursor: pointer"
												class="custom-control-label"
											>
												{{ s }}
											</label>
										</div>
									</div>

									<!--
									<CSwitch
										v-for="s in status"
										:key="'radio' + props.rowData.id + s"
										class="mx-1"
										:color="colorBadge(s)"
										variant="3d"
										:v-bind="{
											labelOn: '\u2713',
											labelOff: '\u2715',
										}"
										type="radio"
										:name="'radio' + props.rowData.id"
										:checked="false"
										:value="s"
										size="sm"
									/>
									-->
								</template>
								<template slot="slot-bayar" slot-scope="props">
									Jumlah : {{ props.rowData.jumlah.toLocaleString() }}<br />
									Ongkir : {{ props.rowData.ongkir.toLocaleString() }}<br />
									<b>
										BAYAR :
										{{
											(
												props.rowData.hargaDiskon + props.rowData.ongkir
											).toLocaleString()
										}}
									</b>
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Modal title 2"
			size="lg"
			color="info"
		>
			<CRow>
				<CCol md="12">
					<CTextarea
						label=""
						rows="4"
						placeholder="Catatan Status"
						v-model="statusSelect.catatan"
					/>
				</CCol>
			</CRow>
			<template #header>
				<h6 class="modal-title">Catatan Status {{ statusSelect.status }}</h6>
				<CButtonClose @click="modal = false" class="text-white" />
			</template>
			<template #footer>
				<CButton @click="modal = false" color="danger">CANCEL</CButton>
				<CButton
					@click="
						changeStat(
							statusSelect.id,
							statusSelect.status,
							statusSelect.catatan,
							false
						)
					"
					:disabled="loading"
					color="success"
					>SAVE</CButton
				>
			</template>
		</CModal>
	</div>
</template>

<script>
import { basuri, debounce } from "@/plugins/utils";
import OrderDetail from "./OrderDetail";

export default {
	components: {
		OrderDetail,
	},
	data() {
		return {
			uri: basuri + "order",
			moreParams: { status: null, filter: null, t_: Date.now() },
			loading: false,
			modal: false,
			statusSelect: { id: 0, i: 0, status: null, catatan: null },
			detailRow: OrderDetail,
			frm: {
				nama: "",
				harga: 0,
				hargaBeli: 0,
				lokasi: 0,
				produksi: "import",
			},
			status: [
				"ready",
				"packing",
				"kirim",
				"cancel",
				"return",
				"selesai",
				"kirim ulang",
			],

			fields: [
				{
					name: "slot-kode",
					title: "Kode",
					sortField: "kode",
				},
				{
					name: "slot-tujuan",
					title: "Tujuan",
					dataClass: "text-left",
				},
				{
					name: "slot-harga",
					title: "Harga",
					dataClass: "text-left",
				},
				{
					name: "slot-kirim",
					title: "Kirim",
					dataClass: "text-left",
				},
				{
					name: "slot-bayar",
					title: "Bayar",
					dataClass: "text-left",
				},
				{
					name: "createdAt",
					title: "Tanggal",
					sortField: "createdAt",
				},
				{
					name: "slot-user",
					title: "User",
					dataClass: "text-left",
					visible: true,
				},
				{
					name: "slot-setstatus",
					title: "",
					visible: false,
				},
				{
					name: "slot-actions",
					title: "",
				},
			],
		};
	},
	watch: {
		"moreParams.filter": debounce(function (val) {
			//if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
			this.$refs.vuetable.refresh();
		}, 700),
		"moreParams.status": debounce(function (val) {
			this.$refs.vuetable.refresh();
		}, 700),
	},
	methods: {
		reset() {
			this.moreParams = { status: null, filter: null, t_: Date.now() };
			//this.$refs.vuetable.refresh();
		},
		add() {
			this.$router.push({ name: "Order Form" });
		},
		colorBadge(st) {
			if (st == "ready") return "info";
			if (st == "packing") return "warning";
			if (st == "kirim") return "success";
			if (st == "cancel") return "danger";
			if (st == "return") return "danger";
			return "danger";
		},
		setStatus(e) {
			if (e.target.value == 1) {
				this.fields[6].visible = false;
				this.fields[7].visible = true;
			} else {
				this.fields[6].visible = true;
				this.fields[7].visible = false;
			}
			this.$refs.vuetable.normalizeFields();
		},
		async changeStat(id, status, catatan, dariLoop) {
			//console.log(e.target.value);
			try {
				this.statusSelect = { id, status, catatan };

				if ((status == "cancel" || status == "return") && dariLoop) {
					this.modal = true;
					return;
				}
				let datar = {};
				const { data } = await this.$http.put("order/" + this.statusSelect.id, {
					status: this.statusSelect.status,
					catatan: this.statusSelect.catatan,
				});

				datar = data;

				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					//this.$refs.vuetable.refresh();
					this.modal = false;
				} else {
					this.$swal("Fail !", datar.message, "error");
				}
			} catch (error) {
				this.$swal("Fail !", datar.message, "error");
			}
			this.statusSelect = { id: 0, status: null, catatan: null };
		},
	},
};
</script>
